import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Section = styled.section`
  background-color: var(--color-white);
  padding: 0 100px;
  @media ${breakpoints.lessThan(sizes.tablet)} {
    padding: 0 40px;
  }
`

export const Header = styled.header`
  /* border: 1px solid red; */
`

export const Content = styled.div`
  height: calc(100vh - 157px);
  min-height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .card.-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: -60px auto 0;

    .img-wrapper {
      margin: 0 auto 25px;
      .img {
        width: 100%;
        height: auto;
        max-width: 200px;
        @media ${breakpoints.lessThan(sizes.phone)} {
          max-width: 130px;
        }
      }
    }

    .c-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .c-title {
        font-size: 2rem;
        text-transform: uppercase;
        color: var(--color-grayscale6);
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
      }

      .c-paragraph {
        margin-bottom: 40px;
        color: var(--color-grayscale6);
        font-size: 1.2rem;
        text-align: center;
        line-height: 1.3;
        font-weight: normal;
      }

      .btn {
        text-transform: uppercase;
        font-weight: normal;
      }
    }
  }
`
