import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionError from "../components/Section/SectionError"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Erro 404: Página não encontrada | Ruy Molina Advocacia" />
      <SectionError />
      <WppButtonFloat />
    </Layout>
  )
}

export default NotFoundPage
