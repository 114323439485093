import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { Button } from "../../Button"

import * as S from "./styled"

const SectionError = () => (
  <S.Section>
    <S.Header>
      <h2 className="sr-only">Erro</h2>
    </S.Header>
    <S.Content>
      <div className="card -error">
        <StaticImage
          className="img-wrapper"
          imgClassName="img"
          src="../../../images/error.svg"
          objectFit="contain"
          objectPosition="left"
          placeholder="none"
          alt="Erro 404"
        />
        <div className="c-content">
          <h3 className="c-title">404</h3>
          <p className="c-paragraph">A página não foi encontrada</p>
          <Button
            className="btn"
            as={AniLink}
            theme="primary"
            to="/"
            cover
            direction="left"
            bg="#EAEAEA"
            duration={1}
            title="Voltar para o site"
          >
            Voltar para o site
          </Button>
        </div>
      </div>
    </S.Content>
  </S.Section>
)

export default SectionError
